import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Page, Preloader } from "lib/common";
import { checkActivityFetching } from "selectors/activity";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ActivityActions from "actions/ActivityActions";
import ActivityItem from "nlib/pages/ActivityPage/lib/ActivityItem";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import PageHeader from "lib/common/PageHeader";
import React, { useCallback, useEffect, useState } from "react";
import TasksBlock from "nlib/common/TasksBlock";
import Utils from "utils/Utils";
import moment from "moment";

const ACTIVITY_PAGE_SIZE = 20;

const ActivityPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const activityFetching = useSelector(checkActivityFetching);

  const [activityData, setActivityData] = useState([]);

  const [showMoreShown, setShowMoreShown] = useState(false);

  const { uiTexts } = useSelector(getTextsData);

  const { id: selectedBusinessId, name: businessName } = useSelector(getSelectedBusinessData);

  const [currentPage, setCurrentPage] = useState(1);

  const todayData = activityData.filter(({ timestamp }) => moment(timestamp).isSame(new Date(), "day"));

  const thisMonthData = activityData.filter(({ timestamp }) => {
    return !moment(timestamp).isSame(new Date(), "day") && moment(timestamp).isSame(new Date(), "month");
  });

  const earlyData = activityData.filter(({ timestamp }) => {
    return !moment(timestamp).isSame(new Date(), "month");
  });

  const updateItems = useCallback((page = 0) => {
    dispatch(
      ActivityActions.fetchActivityList({
        backgroundUpdate: true,
        limit: ACTIVITY_PAGE_SIZE,
        offset: page * ACTIVITY_PAGE_SIZE
      })
    ).then((result) => {
      if (!Array.isArray(result)) return;

      const newItems = result.filter(({ id }) => !Utils.arrayFindById(activityData, id));

      setActivityData([...newItems, ...activityData]);

      if (newItems.length < ACTIVITY_PAGE_SIZE) return;

      updateItems(page + 1);
    });
  }, [activityData, dispatch]);

  const handleShowMoreClick = useCallback(() => {
    setCurrentPage((prev) => prev + 1);
  }, []);

  useEffect(() => {
    return Utils.setTimeout(() => updateItems(), Constants.DATA_LIST_UPDATE_INTERVAL);
  }, [updateItems]);

  useEffect(() => {
    (async() => {
      const result = await dispatch(
        ActivityActions.fetchActivityList({
          limit: ACTIVITY_PAGE_SIZE,
          offset: (currentPage - 1) * ACTIVITY_PAGE_SIZE
        })
      );

      if (!Array.isArray(result)) return;
      setShowMoreShown(result.length === ACTIVITY_PAGE_SIZE);
      setActivityData((prev) => [
        ...prev,
        ...result.filter((item) => !Utils.arrayFindById(prev, item.id))
      ]);
    })();
  }, [currentPage, dispatch, history, selectedBusinessId]);

  return (
    <Page className={Css.activityPage}>
      <TasksBlock className={CommonCss.tasksBlock} />
      <PageHeader subTitle={businessName} />
      {!!todayData.length && (
        <div className={Css.container}>
          <div className={Css.section}>
            <div className={Css.title}>{uiTexts.today}</div>
            {todayData.map((item) => <ActivityItem key={item.id} item={item} />)}
          </div>
        </div>
      )}
      {!!thisMonthData.length && (
        <div className={Css.container}>
          <div className={Css.section}>
            <div className={Css.title}>{uiTexts.thisMonth}</div>
            {thisMonthData.map((item) => <ActivityItem key={item.id} item={item} />)}
          </div>
        </div>
      )}
      {!!earlyData.length && (
        <div className={Css.container}>
          <div className={Css.section}>
            <div className={Css.title}>{uiTexts.early}</div>
            {earlyData.map((item) => <ActivityItem key={item.id} item={item} />)}
          </div>
        </div>
      )}
      {showMoreShown && (
        <div className={Css.showMore} onClick={handleShowMoreClick}>
          <Button large outline>{uiTexts.showMore}</Button>
        </div>
      )}
      {!activityFetching && !activityData.length && (
        <div className={CommonCss.noDataContent}>
          <div>
            <div><Icons.Lightning /></div>
            <div>{uiTexts.noActivity}</div>
          </div>
        </div>
      )}
      {activityFetching && <Preloader absolute />}
    </Page>
  );
};

export default React.memo(ActivityPage);
