import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import ActivityEvents from "const/ActivityEvents";
import Constants from "const/Constants";
import React from "react";
import classNames from "classnames";
import moment from "moment";

const ExpandedListItem = (props) => {
  const {
    type,
    item: {
      amount,
      date,
      expense,
      payee,
      category,
      description,
      originalName
    },
    withCategory,
    witPayee
  } = props;

  return (
    <div className={Css.expandedListItem}>
      {type === ActivityEvents.TYPES.DOCUMENT_RECOGNITION_FAILED
        ? <div className={Css.description} title={originalName}>{originalName}</div>
        : (
          <>
            <div
              title={amount}
              className={classNames(Css.amount, CommonCss.coloredNumber)}
              data-negative={expense ? "" : undefined}>
              {amount}
            </div>
            <div className={Css.date}>
              {date
                ? moment.utc(date).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                : Constants.EMPTY_PLACEHOLDER}
            </div>
            {witPayee && (
              <div className={Css.payee} title={payee}>{payee}</div>
            )}
            {withCategory && (
              <div className={Css.category} title={category}>{category}</div>
            )}
            <div className={Css.description} title={description}>{description}</div>
          </>
        )}

    </div>
  );
};

export default React.memo(ExpandedListItem);
