import Css from "./style.module.scss";

import logoUrl from "assets/coloredLogo.svg";

import * as Icons from "@phosphor-icons/react";
import { Avatar } from "nlib/ui";
import { Link } from "react-router-dom";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import Constants from "const/Constants";
import ExpandedListItem from "./lib/ExpandedListItem";
import React, { useCallback, useMemo } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useActivityEventDescription from "hooks/useActivityEventDescription";

const { DATETIME_FORMATS: { DATETIME_TEXT } } = Constants;

const {
  TYPES: {
    NEW_BUSINESS_ADDED,
    RPA_STARTED,
    RPA_FINISHED,
    RPA_FAILED,
    GPT_AI_FINE_TUNE_STARTED,
    GPT_AI_FINE_TUNE_FINISHED,
    GPT_AI_FINE_TUNE_FAILED,
    TRANSACTIONS_NOT_CATEGORIZED,
    TRANSACTIONS_AUTO_CATEGORIZED,
    TRANSACTIONS_AUTO_PAIRED,
    TRANSACTIONS_CATEGORIZED,
    TRANSACTIONS_AUTO_APPROVED,
    TRANSACTIONS_APPROVED,
    TRANSACTIONS_AUTO_ASK_CLIENT,
    TRANSACTIONS_ASK_CLIENT,
    TRANSACTIONS_REPLIED_BY_CLIENT,
    USER_INVITED,
    USER_INVITE_FOLLOW_UP,
    USER_REVOKED,
    DOCUMENTS_UPLOADED,
    DOCUMENTS_APPROVED,
    DOCUMENT_RECOGNITION_FAILED,
    TRANSACTIONS_ASK_CLIENT_FOLLOW_UP,
    TASK_ADDED,
    TASK_REMOVED,
    TASK_MARKED_AS_COMPLETED
  }
} = ActivityEvents;

const ICONS_BY_TYPE = {
  [TASK_ADDED]: Icons.CheckSquareOffset,
  [TASK_REMOVED]: Icons.Trash,
  [TASK_MARKED_AS_COMPLETED]: Icons.CheckSquare,
  [USER_INVITE_FOLLOW_UP]: Icons.Envelope,
  [TRANSACTIONS_ASK_CLIENT_FOLLOW_UP]: Icons.Envelope,
  [NEW_BUSINESS_ADDED]: Icons.Briefcase,
  [TRANSACTIONS_CATEGORIZED]: Icons.Tag,
  [TRANSACTIONS_APPROVED]: Icons.Check,
  [TRANSACTIONS_ASK_CLIENT]: Icons.Question,
  [TRANSACTIONS_REPLIED_BY_CLIENT]: Icons.ChatText,
  [RPA_STARTED]: Icons.Cpu,
  [RPA_FINISHED]: Icons.Cpu,
  [RPA_FAILED]: Icons.Cpu,
  [GPT_AI_FINE_TUNE_STARTED]: Icons.Cpu,
  [GPT_AI_FINE_TUNE_FINISHED]: Icons.Cpu,
  [GPT_AI_FINE_TUNE_FAILED]: Icons.Cpu,
  [TRANSACTIONS_NOT_CATEGORIZED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_CATEGORIZED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_PAIRED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_APPROVED]: Icons.Cpu,
  [TRANSACTIONS_AUTO_ASK_CLIENT]: Icons.Cpu,
  [USER_INVITED]: Icons.UserPlus,
  [USER_REVOKED]: Icons.UserMinus,
  [DOCUMENTS_UPLOADED]: Icons.CloudArrowUp,
  [DOCUMENTS_APPROVED]: Icons.Check,
  [DOCUMENT_RECOGNITION_FAILED]: Icons.CloudSlash
};

const TYPE_COLOR = {
  [RPA_FAILED]: Css.failed,
  [GPT_AI_FINE_TUNE_FAILED]: Css.failed
};

const { PROJECT_NAME } = Utils.getProcessEnvVars();

const { TRANSACTIONS } = UiRoutes;

const ActivityItem = ({ item }) => {
  const {
    id: activityId,
    type,
    system,
    info,
    timestamp,
    expandedList,
    linkable
  } = item;

  const { uiTexts } = useSelector(getTextsData);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const Icon = ICONS_BY_TYPE[type] || Icons.Pulse;

  const diff = moment(timestamp).diff(moment());

  const getUserName = useCallback((user, fallback = uiTexts.unknown) => {
    return (user && (user.userFullName || user.email)) || fallback;
  }, [uiTexts.unknown]);

  const description = useActivityEventDescription(item);

  const userName = useMemo(() => {
    if (system) return PROJECT_NAME;

    const { user, sender, recipient, businessName } = info;

    return getUserName(user, null) || getUserName(sender, null) || getUserName(recipient, null)
      || businessName || uiTexts.unknown;
  }, [getUserName, info, system, uiTexts.unknown]);

  const itemsCount = info.transactionsCount;

  const withCategory = useMemo(() => {
    return expandedList.some(({ category }) => category);
  }, [expandedList]);

  const witPayee = useMemo(() => {
    return expandedList.some(({ payee }) => payee);
  }, [expandedList]);

  return (
    <div className={classNames(Css.listItem, system && Css.system)}>
      <div className={classNames(Css.type, TYPE_COLOR[type])}><Icon /></div>
      <div className={Css.card}>
        <div className={Css.title}>
          <Avatar
            className={Css.avatar}
            src={system ? logoUrl : undefined}
            title={system ? PROJECT_NAME : userName} />
          <div className={Css.label}>{description}</div>
          <div className={Css.date}>
            {Math.abs(diff) > moment.duration(1, "days").asMilliseconds()
              ? moment(timestamp).format(DATETIME_TEXT)
              : moment.duration(diff).humanize(true)}
          </div>
        </div>
        {!!expandedList?.length && (
          <div className={Css.list}>
            {expandedList
              .map((listItem, index) => (
                <ExpandedListItem
                  key={String(index + 1)}
                  type={type}
                  item={listItem}
                  withCategory={withCategory}
                  witPayee={witPayee} />
              ))}
            <div className={Css.footer}>
              <div>
                {!!((itemsCount - expandedList.length) > 0) && (
                  <div className={Css.more}>
                    {Utils.replaceTextVars(uiTexts.andCountMore.toLowerCase(), { count: itemsCount - expandedList.length })}
                  </div>
                )}
              </div>
              {linkable && (
                <Link to={`/${selectedBusinessId}${TRANSACTIONS}?text=activityId:${activityId}`}>{uiTexts.viewDetails}</Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ActivityItem);
