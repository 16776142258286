export default class ActivityEvents {
  static TYPES = {
    DOCUMENTS_APPROVED: "documentsApproved",
    DOCUMENTS_UPLOADED: "documentsUploaded",
    DOCUMENT_RECOGNITION_FAILED: "documentRecognitionFailed",
    GPT_AI_FINE_TUNE_FAILED: "gptAiFineTuneFailed",
    GPT_AI_FINE_TUNE_FINISHED: "gptAiFineTuneFinished",
    GPT_AI_FINE_TUNE_STARTED: "gptAiFineTuneStarted",
    NEW_BUSINESS_ADDED: "newBusinessAdded",
    RPA_FAILED: "rpaFailed",
    RPA_FINISHED: "rpaFinished",
    RPA_STARTED: "rpaStarted",
    TASK_ADDED: "taskAdded",
    TASK_MARKED_AS_COMPLETED: "taskMarkedAsCompleted",
    TASK_REMOVED: "taskRemoved",
    TRANSACTIONS_APPROVED: "transactionsApproved",
    TRANSACTIONS_ASK_CLIENT_FOLLOW_UP: "transactionsAskClientFollowUp",
    TRANSACTIONS_ASK_CLIENT: "transactionsAskClient",
    TRANSACTIONS_AUTO_APPROVED: "transactionsAutoApproved",
    TRANSACTIONS_AUTO_ASK_CLIENT: "transactionsAutoAskClient",
    TRANSACTIONS_AUTO_CATEGORIZED: "transactionsAutoCategorized",
    TRANSACTIONS_AUTO_PAIRED: "transactionsAutoPaired",
    TRANSACTIONS_CATEGORIZED: "transactionsCategorized",
    TRANSACTIONS_NOT_CATEGORIZED: "transactionsNotCategorized",
    TRANSACTIONS_REPLIED_BY_CLIENT: "transactionsRepliedByClient",
    USER_INVITE_FOLLOW_UP: "userInviteFollowUp",
    USER_INVITED: "userInvited",
    USER_REVOKED: "userRevoked"
  };
}
