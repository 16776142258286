import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import Constants from "const/Constants";
import React, { Fragment, useCallback, useMemo } from "react";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";

const {
  TYPES: {
    NEW_BUSINESS_ADDED,
    RPA_STARTED,
    RPA_FINISHED,
    RPA_FAILED,
    GPT_AI_FINE_TUNE_STARTED,
    GPT_AI_FINE_TUNE_FINISHED,
    GPT_AI_FINE_TUNE_FAILED,
    TRANSACTIONS_NOT_CATEGORIZED,
    TRANSACTIONS_AUTO_CATEGORIZED,
    TRANSACTIONS_AUTO_PAIRED,
    TRANSACTIONS_CATEGORIZED,
    TRANSACTIONS_AUTO_APPROVED,
    TRANSACTIONS_APPROVED,
    TRANSACTIONS_AUTO_ASK_CLIENT,
    TRANSACTIONS_ASK_CLIENT,
    TRANSACTIONS_REPLIED_BY_CLIENT,
    USER_INVITED,
    USER_INVITE_FOLLOW_UP,
    USER_REVOKED,
    TRANSACTIONS_ASK_CLIENT_FOLLOW_UP,
    DOCUMENTS_UPLOADED,
    DOCUMENTS_APPROVED,
    DOCUMENT_RECOGNITION_FAILED,
    TASK_ADDED,
    TASK_REMOVED,
    TASK_MARKED_AS_COMPLETED
  }
} = ActivityEvents;

const ODD = 2;

const useActivityEventDescription = ({ type, info }) => {
  const { uiTexts, messages, errors } = useSelector(getTextsData);

  const getUserName = useCallback((user, fallback = uiTexts.unknown) => {
    return (user && (user.userFullName || user.email)) || fallback;
  }, [uiTexts.unknown]);

  const workflowNames = useMemo(() => {
    return {
      [Constants.RPA_WORKFLOWS.PARSE_TRANSACTIONS]: uiTexts.parseTransactions,
      [Constants.RPA_WORKFLOWS.MATCH_TRANSACTIONS]: uiTexts.matchTransactions,
      [Constants.RPA_WORKFLOWS.RECONCILE_TRANSACTONS]: uiTexts.reconcileTransactions
    };
  }, [uiTexts]);

  const description = useMemo(() => {
    switch (type) {
      case NEW_BUSINESS_ADDED:
        return Utils.replaceTextVars(messages.systemEventNewBusinessAdded, {
          businessName: info.businessName
        });
      case RPA_STARTED:
        return Utils.replaceTextVars(messages.systemEventRpaStarted, {
          workflowName: workflowNames[info.workflow] || info.workflow
        });
      case RPA_FINISHED:
        if (info.workflow === Constants.RPA_WORKFLOWS.PARSE_TRANSACTIONS) {
          return info.transactionsCount
            ? Utils.replaceTextVars(
              messages.systemEventRpaFinishedQboParsing,
              { count: info.transactionsCount }
            )
            : messages.systemEventRpaFinishedQboParsingZero;
        }
        if (info.workflow === Constants.RPA_WORKFLOWS.MATCH_TRANSACTIONS) {
          if (info.transactionsCount) {
            if (info.transactionsCount === info.transfersCount) {
              return Utils.replaceTextVars(
                messages.systemEventRpaFinishedQboMatchingTransfers,
                { count: info.transactionsCount }
              );
            } else if (info.transfersCount) {
              return Utils.replaceTextVars(
                messages.systemEventRpaFinishedQboMatchingWithTransfers,
                { transactionsCount: info.transactionsCount - info.transfersCount, transfersCount: info.transfersCount }
              );
            }

            return Utils.replaceTextVars(
              messages.systemEventRpaFinishedQboMatching,
              { count: info.transactionsCount }
            );
          }

          return messages.systemEventRpaFinishedQboMatchingZero;
        }
        if (info.workflow === Constants.RPA_WORKFLOWS.RECONCILE_TRANSACTONS) {
          return info.transactionsCount
            ? Utils.replaceTextVars(
              messages.systemEventRpaFinishedXeroReconciliation,
              { count: info.transactionsCount }
            )
            : messages.systemEventRpaFinishedXeroReconciliationZero;
        }

        return messages.systemEventRpaFinished;
      case RPA_FAILED:
        return Utils.replaceTextVars(messages.systemEventRpaFailed, {
          message: info.errorMessage || errors.unknown
        });
      case GPT_AI_FINE_TUNE_STARTED:
        return messages.systemEventGptAiFineTuneStarted;
      case GPT_AI_FINE_TUNE_FINISHED:
        return info.dryRun ? messages.systemEventGptAiFineTuneFinishedDryRun : messages.systemEventGptAiFineTuneFinished;
      case GPT_AI_FINE_TUNE_FAILED:
        return Utils.replaceTextVars(messages.systemEventGptAiFineTuneFailed, {
          message: info.errorMessage || errors.unknown
        });
      case TRANSACTIONS_NOT_CATEGORIZED:
        return Utils.replaceTextVars(messages.systemEventTransactionsNotCategorized, {
          count: info.transactionsCount
        });
      case TRANSACTIONS_AUTO_CATEGORIZED:
        return Utils.replaceTextVars(messages.systemEventTransactionsAutoCategorized, {
          count: info.transactionsCount
        });
      case TRANSACTIONS_AUTO_PAIRED:
        return Utils.replaceTextVars(messages.systemEventTransactionsAutoPaired, {
          count: info.transactionsCount
        });
      case TRANSACTIONS_CATEGORIZED:
        return Utils.replaceTextVars(messages.systemEventTransactionsCategorized, {
          userName: getUserName(info.user),
          count: info.transactionsCount
        });
      case TRANSACTIONS_AUTO_APPROVED:
        return info.queuedTransactionsCount
          ? Utils.replaceTextVars(messages.systemEventTransactionsAutoApprovedXeroLimit, {
            count: info.transactionsCount,
            limit: info.maxTransactionsLimit,
            queuedCount: info.queuedTransactionsCount
          })
          : Utils.replaceTextVars(messages.systemEventTransactionsAutoApproved, {
            count: info.transactionsCount
          });
      case TRANSACTIONS_APPROVED:
        return Utils.replaceTextVars(messages.systemEventTransactionsApproved, {
          userName: getUserName(info.user),
          count: info.transactionsCount
        });
      case TRANSACTIONS_AUTO_ASK_CLIENT: {
        return Utils.replaceTextVars(messages.systemEventTransactionsAutoAskClient, {
          recipientName: getUserName(info.recipient),
          count: info.transactionsCount
        });
      }
      case TRANSACTIONS_ASK_CLIENT: {
        return Utils.replaceTextVars(messages.systemEventTransactionsAskClient, {
          senderName: getUserName(info.sender),
          recipientName: getUserName(info.recipient),
          count: info.transactionsCount
        });
      }
      case TRANSACTIONS_REPLIED_BY_CLIENT: {
        return Utils.replaceTextVars(messages.systemEventTransactionsRepliedByClient, {
          userName: getUserName(info.user),
          count: info.transactionsCount
        });
      }
      case USER_INVITED: {
        return Utils.replaceTextVars(messages.systemEventUserInvited, {
          senderName: getUserName(info.sender),
          recipientName: getUserName(info.recipient),
          recipientRole: (uiTexts[UserRoles.getLangId(info.recipient?.role)] || "").toLowerCase()
        });
      }
      case USER_INVITE_FOLLOW_UP: {
        return Utils.replaceTextVars(messages.systemEventUserInviteFollowUp, {
          recipientName: getUserName(info.recipient)
        });
      }
      case USER_REVOKED: {
        return Utils.replaceTextVars(messages.systemEventUserRevoked, {
          senderName: getUserName(info.sender),
          recipientName: getUserName(info.recipient),
          recipientRole: (uiTexts[UserRoles.getLangId(info.recipient?.role)] || "").toLowerCase()
        });
      }
      case TRANSACTIONS_ASK_CLIENT_FOLLOW_UP: {
        return Utils.replaceTextVars(messages.systemEventTransactionsAskClientFollowUp, {
          recipientName: getUserName(info.recipient),
          count: info.transactionsCount
        });
      }
      case DOCUMENTS_UPLOADED: {
        return Utils.replaceTextVars(messages.systemEventDocumentsUploaded, {
          userName: getUserName(info.user),
          count: info.documentsCount
        });
      }
      case DOCUMENTS_APPROVED: {
        return Utils.replaceTextVars(messages.systemEventDocumentsApproved, {
          userName: getUserName(info.user),
          count: info.documentsCount
        });
      }
      case DOCUMENT_RECOGNITION_FAILED: {
        return Utils.replaceTextVars(messages.systemEventDocumentRecognitionFailed, {
          count: info.documentsCount,
          message: info.errorMessage
        });
      }
      case TASK_ADDED: {
        return Utils.replaceTextVars(messages.systemEventTaskAdded, {
          senderName: getUserName(info.sender),
          recipientName: getUserName(info.recipient),
          title: info.taskTitle
        });
      }
      case TASK_REMOVED: {
        return Utils.replaceTextVars(messages.systemEventTaskDeleted, {
          userName: getUserName(info.user),
          title: info.taskTitle
        });
      }
      case TASK_MARKED_AS_COMPLETED: {
        return Utils.replaceTextVars(messages.systemEventTaskMarkedAsCompleted, {
          userName: getUserName(info.user),
          title: info.taskTitle
        });
      }
      default:
        return messages.systemEventUnknown;
    }
  }, [type, info, workflowNames, uiTexts, messages, errors, getUserName]);

  return useMemo(() => {
    return description.split("*").map((str, index) => {
      return <Fragment key={String(index + 1)}>{index % ODD ? <b>{str}</b> : <span>{str}</span>}</Fragment>;
    });
  }, [description]);
};

export default useActivityEventDescription;
